<!--
 * @Author: ch3nh2
 * @Date: 2023-04-12 09:45:02
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-04-18 17:20:05
 * @FilePath: /beilunchanye_fe_20211208/src/views/gdds.vue
 * @Description: 高德大厦
-->
<template>
	<div class="home gdds">
		<div
			v-if="$store.state.animateDelay && $store.state.mapType"
			class="gdds-wrap gdds-left-wrap"
			:class="{
				animate__animated: true,
				animate__fadeInLeftBig: $store.state.showDataValue,
				animate__fadeOutLeftBig: !$store.state.showDataValue,
			}"
		>
			<div class="gdds-item-wrap-global gdds-item-wrap-01">
				<div class="gdds-item-01-item">
					<div class="gdds-item-01-item-icon-wrap">
						<div class="gdds-item-01-item-icon"></div>
						<div class="gdds-item-01-item-name">大厦介绍</div>
					</div>
					<div class="gdds-item-01-item-text">高德大厦，地处京城两大交通主动脉—北三环（北太平庄桥）与北四环（志新桥）之间，是金街—西单的延线，由新外大街贯通南北，知春路横跨东西；不仅由中关村、亚奥、马甸三大商圈环抱，同时还是中关村金融走廊的东门户；周边数十路公交与建设中的地铁十号线联网构建出多重出行版图——驱车20分钟直达首都国际机场；距海淀各政府机关不过5分钟车程；远离中关村的喧嚣，静享中关村高科技园区优惠政策。</div>
				</div>
				<div class="gdds-item-01-item">
					<div class="gdds-item-01-item-icon-wrap">
						<div class="gdds-item-01-item-icon"></div>
						<div class="gdds-item-01-item-name">园区定位</div>
					</div>
					<div class="gdds-item-01-item-text">项目与北京公司周边项目形成联动，打造以人工智能为主的军民融合生态集群</div>
				</div>
			</div>
			<div class="gdds-item-wrap-global gdds-item-wrap-02">
				<div class="gdds-item-wrap-02-wrap">
					<div class="gdds-item-02-item">
						<div class="gdds-item-02-item-chart">
							<div id="Chart" :style="{ width: '140px', height: '140px' }"></div>
							<div class="gdds-item-02-item-chart-data">
								<div class="gdds-item-02-item-chart-circle"></div>
								<div class="gdds-item-02-item-chart-num">
									<CountUp :delay="1000" :endVal="62" />
									<b>%</b>
								</div>
								<div class="gdds-item-02-item-chart-name">占比</div>
							</div>
						</div>
						<div class="gdds-item-02-item-01">
							<div class="gdds-item-02-item-01-item">
								<div class="gdds-item-02-item-01-item-name">可租售面积</div>
								<div class="gdds-item-02-item-01-item-num">
									<CountUp :delay="1000" :endVal="19273.92" :options="{ decimalPlaces: 2 }" />
									<b>m²</b>
								</div>
							</div>
							<div class="gdds-item-02-item-01-item">
								<div class="gdds-item-02-item-01-item-name">已租售面积</div>
								<div class="gdds-item-02-item-01-item-num">
									<CountUp :delay="1000" :endVal="11983.72" :options="{ decimalPlaces: 2 }" />
									<b>m²</b>
								</div>
							</div>
						</div>
					</div>
					<div class="gdds-item-02-item">
						<div class="gdds-item-02-item-02">
							<div class="gdds-item-02-item-02-icon"></div>
							<div class="gdds-item-02-item-02-data">
								<div class="gdds-item-02-item-02-num">
									<CountUp :delay="1000" :endVal="22699.26" />
									<b>m²</b>
								</div>
								<div class="gdds-item-02-item-02-name">总建筑面积</div>
							</div>
						</div>
						<div class="gdds-item-02-item-02">
							<div class="gdds-item-02-item-02-icon"></div>
							<div class="gdds-item-02-item-02-data">
								<div class="gdds-item-02-item-02-num">
									<CountUp :delay="1000" :endVal="19273.93" />
									<b>m²</b>
								</div>
								<div class="gdds-item-02-item-02-name">北京总建筑面积</div>
							</div>
						</div>
						<div class="gdds-item-02-item-02">
							<div class="gdds-item-02-item-02-icon"></div>
							<div class="gdds-item-02-item-02-data">
								<div class="gdds-item-02-item-02-num">
									<CountUp :delay="1000" :endVal="13" />
									<b>层</b>
								</div>
								<div class="gdds-item-02-item-02-name">楼层</div>
							</div>
						</div>
						<div class="gdds-item-02-item-02">
							<div class="gdds-item-02-item-02-icon"></div>
							<div class="gdds-item-02-item-02-data">
								<div class="gdds-item-02-item-02-num">
									<CountUp :delay="1000" :endVal="5.44" :options="{ decimalPlaces: 2 }" />
									<b></b>
								</div>
								<div class="gdds-item-02-item-02-name">容积率</div>
							</div>
						</div>
					</div>
					<div class="gdds-item-02-item">
						<div class="gdds-item-02-item-03">
							<div class="gdds-item-02-item-03-icon"></div>
							<div class="gdds-item-02-item-03-data">
								<div class="gdds-item-02-item-03-num">
									<CountUp :delay="1000" :endVal="34" />
									<b>家</b>
								</div>
								<div class="gdds-item-02-item-03-name">企业数量</div>
							</div>
						</div>
						<div class="gdds-item-02-item-03">
							<div class="gdds-item-02-item-03-icon"></div>
							<div class="gdds-item-02-item-03-data">
								<div class="gdds-item-02-item-03-num">
									<CountUp :delay="1000" :endVal="700" />
									<b>家</b>
								</div>
								<div class="gdds-item-02-item-03-name">员工总人数</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="$store.state.animateDelay && $store.state.mapType"
			class="gdds-wrap gdds-right-wrap"
			:class="{
				animate__animated: true,
				animate__fadeInRightBig: $store.state.showDataValue,
				animate__fadeOutRightBig: !$store.state.showDataValue,
			}"
		>
			<div class="gdds-right-wrap-mask">
				<div class="gdds-item-wrap-global gdds-item-wrap-03">
					<div class="gdds-item-wrap-list">
						<div v-for="(item, index) in storey" :key="index" class="gdds-item-03-item">
							<div class="gdds-item-03-item-index">{{ item.index }}F</div>
							<div class="gdds-item-03-item-icon"></div>
							<div class="gdds-item-03-item-data">
								<div class="gdds-item-03-item-data-proportion" :style="`width: ${item.proportion}%`"><CountUp :delay="1000" :endVal="item.proportion" :options="{ decimalPlaces: 2 }" /><b>%</b></div>
								<div class="gdds-item-03-item-data-border">
									<div class="gdds-item-03-item-data-background">
										<div class="gdds-item-03-item-data-progress" :style="`width: ${item.proportion}%`">
											<div class="gdds-item-03-item-data-circle"></div>
										</div>
									</div>
								</div>
							</div>
							<div class="gdds-item-03-item-num">
								<CountUp :delay="1000" :endVal="item.rented" :options="{ decimalPlaces: 2 }" />
								<b>/</b>
								<CountUp :delay="1000" :endVal="item.unleased" :options="{ decimalPlaces: 2 }" />
							</div>
						</div>
					</div>
				</div>
				<div class="gdds-item-wrap-global gdds-item-wrap-04">
					<div v-if="company && company.length > 0" class="gdds-item-wrap-list">
						<div
							v-for="(item, index) in company"
							:key="index"
							:class="{
								'gdds-item-list-item': true,
								'gdds-item-list-item-active': itemData && itemData.company_name == item.company_name,
							}"
							@click="onItem(item, index)"
						>
							<p>{{ item.company_name }}</p>
						</div>
					</div>
					<div v-else class="gdds-nodata">当前暂无数据</div>
				</div>
			</div>
		</div>
		<CompanyDetails
			name="dahang"
			:visible="visible"
			:data="itemData"
			:onClose="
				() => {
					itemData = null;
					visible = !visible;
				}
			"
		/>
	</div>
</template>

<script>
	import CountUp from 'vue-countup-v2';
	import CompanyDetails from '@/components/companyDetails';
	import { getCompanyList } from '@/api/zgc';

	export default {
		data() {
			return {
				storey: [
					{
						index: '-1',
						rented: 417.12,
						unleased: 221,
						proportion: 65.37,
					},
					{
						index: '1',
						rented: 1371,
						unleased: 0,
						proportion: 100.0,
					},
					{
						index: '2',
						rented: 1409,
						unleased: 0,
						proportion: 100.0,
					},
					{
						index: '3',
						rented: 810,
						unleased: 517,
						proportion: 61.04,
					},
					{
						index: '4',
						rented: 1154,
						unleased: 181,
						proportion: 86.44,
					},
					{
						index: '5',
						rented: 0,
						unleased: 2353,
						proportion: 0.0,
					},
					{
						index: '6',
						rented: 1194,
						unleased: 1180.6,
						proportion: 50.28,
					},
					{
						index: '7',
						rented: 0,
						unleased: 2386.6,
						proportion: 0.0,
					},
					{
						index: '8',
						rented: 2370.6,
						unleased: 0,
						proportion: 100.0,
					},
					{
						index: '9',
						rented: 904,
						unleased: 0,
						proportion: 100.0,
					},
					{
						index: '10',
						rented: 911,
						unleased: 0,
						proportion: 100.0,
					},
					{
						index: '11',
						rented: 640,
						unleased: 313,
						proportion: 67.16,
					},
					{
						index: '12',
						rented: 803,
						unleased: 138,
						proportion: 85.33,
					},
				],
				company: [],
				visible: false,
				itemData: null,
			};
		},
		watch: {},
		computed: {},
		components: {
			CountUp,
			CompanyDetails,
		},
		methods: {
			getData() {
				getCompanyList({
					type: 19,
					limit: 999,
				}).then(res => {
					console.log('获取企业列表数据', res);
					const { data } = res;
					data.map(item => {
						const company_industry = [];
						item.company_keywords = item.company_keywords ? JSON.parse(item.company_keywords) : [];
						item.patent_star = item.patent_star ? JSON.parse(item.patent_star) : null;
						item.intellectual_property_statistics = item.intellectual_property_statistics ? JSON.parse(item.intellectual_property_statistics) : null;
						item.rd_efficiency = item.rd_efficiency ? JSON.parse(item.rd_efficiency) : null;
						item.distribution = item.distribution ? JSON.parse(item.distribution) : [];
						item.company_industry = item.company_industry ? JSON.parse(item.company_industry) : [];
						item.company_industry.map(item => {
							if (item.children && item.children.length > 0) {
								item.children.map(jtem => {
									company_industry.push({
										value: jtem.industry_id,
										label: jtem.industry_name,
									});
								});
							}
						});
						item.company_industry = company_industry;
						item.grade = item.grade ? JSON.parse(item.grade) : [];
						item.tech_rd_strength = item.tech_rd_strength ? JSON.parse(item.tech_rd_strength) : [];
					});
					this.company = data;
				});
			},
			onItem(item, index) {
				this.itemData = item;
				this.visible = false;
				setTimeout(() => {
					this.visible = true;
				}, 100);
			},
			drawChart() {
				const chart = this.$echarts.init(document.getElementById('Chart'));
				chart.setOption({
					color: ['#ffffff', '#00f6ff'],
					series: [
						{
							type: 'pie',
							radius: ['93%', '100%'],
							label: {
								show: false,
							},
							data: [60, 40],
						},
					],
				});
			},
			onLoad(e) {
				const { data } = e;
				const screenWrap = document.getElementById('screenWrap');
				let msg1 = {
					funcName: 'resetFull',
				};
				let msg2 = {
					funcName: 'cameraFly',
					param: {
						id: 21,
					},
				};
				if (data.param && data.param.goStatus) {
					this.$store.commit('changeLoadingStatus', false);
					this.$store.commit('changeMapTypeStatus', true);
					this.$store.commit('changeAnimateDelay', true);
					screenWrap.contentWindow.postMessage(msg1, '*');
					setTimeout(() => {
						screenWrap.contentWindow.postMessage(msg2, '*');
					}, 3000);
					this.drawChart();
				}
			},
		},
		mounted() {
			this.getData();
			window.addEventListener('message', this.onLoad);
		},
		beforeDestory() {
			window.removeEventListener('message', this.onLoad);
		},
	};
</script>

<style lang="less">
	.gdds {
		.gdds-wrap {
			z-index: 10;
			position: absolute;
			width: 445px;
			height: 100%;
		}

		.gdds-left-wrap {
			left: 15px;
		}

		.gdds-right-wrap {
			right: 15px;
			.gdds-right-wrap-mask {
				position: absolute;
				top: 104px;
				width: 447px;
				height: 961px;
				background-size: 100%;
				background-repeat: no-repeat;
				background-image: url(../assets/images/gdds/item-wrap-right.png);
			}
		}
		.gdds-item-wrap-global {
			position: absolute;
			background-size: 100%;
			background-repeat: no-repeat;
		}
		.gdds-item-wrap-01 {
			top: 105px;
			width: 100%;
			height: 318px;
			background-color: rgba(5, 12, 26, 0.5);
			.gdds-item-01-item {
				display: flex;
				align-items: center;
				margin: 22px 0;
				.gdds-item-01-item-icon-wrap {
					width: 147px;
					display: flex;
					flex-shrink: 0;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					.gdds-item-01-item-icon {
						width: 100%;
						height: 100px;
						background-size: 100%;
						background-repeat: no-repeat;
					}
					.gdds-item-01-item-name {
						font-size: 18px;
						font-weight: bold;
						background-clip: text;
						color: transparent;
						background-image: linear-gradient(#b9c3c4, #85c4cd);
					}
				}
				.gdds-item-01-item-text {
					max-height: 90px;
					font-size: 16px;
					margin-right: 18px;
					overflow-y: scroll;
					&::-webkit-scrollbar {
						display: none;
					}
				}
				&:nth-child(1) {
					.gdds-item-01-item-icon {
						background-image: url(../assets/images/gdds/item-01-icon-01.png);
					}
				}
				&:nth-child(2) {
					.gdds-item-01-item-icon {
						background-image: url(../assets/images/gdds/item-01-icon-02.png);
					}
				}
			}
		}
		.gdds-item-wrap-02 {
			left: -5px;
			bottom: 5px;
			width: 455px;
			height: 649px;
			background-image: url(../assets/images/gdds/item-wrap-02.png);
			.gdds-item-wrap-02-wrap {
				position: absolute;
				bottom: 0;
				width: 100%;
				height: 592px;
				.gdds-item-02-item {
					display: flex;
					flex-wrap: wrap;
					padding: 0 20px;
					&:nth-child(1) {
						margin: 30px 0;
						flex-wrap: nowrap;
						.gdds-item-02-item-chart {
							margin: 0 40px;
							display: flex;
							align-items: center;
							justify-content: center;
							.gdds-item-02-item-chart-data {
								position: absolute;
								width: 140px;
								height: 140px;
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								.gdds-item-02-item-chart-circle {
									position: absolute;
									width: 98px;
									height: 91px;
									top: 50%;
									left: 50%;
									margin-top: -42px;
									margin-left: -49.5px;
									background-size: 100%;
									background-repeat: no-repeat;
									background-image: url(../assets/images/gdds/item-02-01-icon-01.png);
								}
								.gdds-item-02-item-chart-num {
									span {
										font-size: 40px;
										margin-right: 4px;
										font-weight: bold;
										font-family: 'bigdsfont';
										background-image: linear-gradient(#ffffff, #b9d7ff);
										background-clip: text;
										color: transparent;
									}
									b {
										font-size: 18px;
									}
								}
								.gdds-item-02-item-chart-name {
								}
							}
						}
						.gdds-item-02-item-01 {
							.gdds-item-02-item-01-item {
								margin-bottom: 20px;
								display: flex;
								flex-direction: column;
								&:last-child {
									margin-bottom: 0;
								}
								.gdds-item-02-item-01-item-name {
									font-size: 18px;
									display: flex;
									align-items: center;
									&::before {
										content: '';
										display: inline-block;
										width: 8px;
										height: 8px;
										margin-right: 10px;
									}
								}
								.gdds-item-02-item-01-item-num {
									span {
										font-size: 42px;
										margin-right: 4px;
										font-weight: bold;
										font-family: 'bigdsfont';
										background-image: linear-gradient(#ffffff, #b9d7ff);
										background-clip: text;
										color: transparent;
									}
									b {
										color: #2fc6dc;
										font-size: 16px;
									}
								}
								&:nth-child(1) {
									.gdds-item-02-item-01-item-name {
										&::before {
											background: #00f6ff;
										}
									}
								}
								&:nth-child(2) {
									.gdds-item-02-item-01-item-name {
										&::before {
											background: #ffffff;
										}
									}
								}
							}
						}
					}
					&:nth-child(2) {
						margin-bottom: 50px;
						.gdds-item-02-item-02 {
							display: flex;
							align-items: center;
							margin-right: 20px;
							margin-bottom: 10px;
							.gdds-item-02-item-02-icon {
								width: 52px;
								height: 52px;
								margin-right: 20px;
								background-size: 100%;
								background-repeat: no-repeat;
							}
							.gdds-item-02-item-02-data {
								display: flex;
								flex-direction: column;
								align-items: center;
								.gdds-item-02-item-02-num {
									span {
										font-size: 42px;
										margin-right: 4px;
										font-weight: bold;
										font-family: 'bigdsfont';
										background-image: linear-gradient(#ffffff, #b9d7ff);
										background-clip: text;
										color: transparent;
									}
									b {
										color: #2fc6dc;
										font-size: 16px;
									}
								}
								.gdds-item-02-item-02-name {
									color: #2fc6dc;
									font-size: 18px;
								}
							}
							&:nth-child(1) {
								.gdds-item-02-item-02-icon {
									background-image: url(../assets/images/gdds/item-02-02-icon-01.png);
								}
							}
							&:nth-child(2) {
								.gdds-item-02-item-02-icon {
									background-image: url(../assets/images/gdds/item-02-02-icon-02.png);
								}
							}
							&:nth-child(3) {
								.gdds-item-02-item-02-icon {
									background-image: url(../assets/images/gdds/item-02-02-icon-03.png);
								}
							}
							&:nth-child(4) {
								.gdds-item-02-item-02-icon {
									background-image: url(../assets/images/gdds/item-02-02-icon-04.png);
								}
							}
						}
					}
					&:nth-child(3) {
						.gdds-item-02-item-03 {
							display: flex;
							align-items: center;
							margin: 0 10px;
							margin-bottom: 10px;
							.gdds-item-02-item-03-icon {
								width: 66px;
								height: 66px;
								margin-right: 10px;
								background-size: 100%;
								background-repeat: no-repeat;
							}
							.gdds-item-02-item-03-data {
								display: flex;
								flex-direction: column;
								align-items: center;
								.gdds-item-02-item-03-num {
									display: flex;
									align-items: center;
									justify-content: center;
									&::before {
										content: '';
										width: 8px;
										height: 9px;
										margin-right: 10px;
										display: inline-block;
										background-size: 100%;
										background-repeat: no-repeat;
										background-image: url(../assets/images/gdds/item-02-03-icon-01.png);
									}
									&::after {
										content: '';
										width: 8px;
										height: 9px;
										margin-left: 10px;
										display: inline-block;
										background-size: 100%;
										background-repeat: no-repeat;
										background-image: url(../assets/images/gdds/item-02-03-icon-02.png);
									}
									span {
										font-size: 42px;
										margin-right: 4px;
										font-weight: bold;
										font-family: 'bigdsfont';
										background-image: linear-gradient(#ffffff, #b9d7ff);
										background-clip: text;
										color: transparent;
									}
									b {
										font-size: 16px;
									}
								}
								.gdds-item-02-item-03-name {
									color: #25a2b5;
									font-size: 20px;
								}
							}
							&:nth-child(1) {
								.gdds-item-02-item-03-icon {
									background-image: url(../assets/images/gdds/item-02-03-icon-03.png);
								}
							}
							&:nth-child(2) {
								.gdds-item-02-item-03-icon {
									background-image: url(../assets/images/gdds/item-02-03-icon-04.png);
								}
							}
						}
					}
				}
			}
		}
		.gdds-item-wrap-03 {
			top: 51px;
			width: 100%;
			height: 398px;
			.gdds-item-wrap-list {
				margin: 20px 15px;
				max-height: 360px;
				overflow-y: scroll;
				&::-webkit-scrollbar {
					display: none;
				}
				.gdds-item-03-item {
					display: flex;
					align-items: flex-end;
					margin-bottom: 10px;
					.gdds-item-03-item-index {
						width: 30px;
						font-size: 18px;
						margin-right: 10px;
						font-weight: bold;
						text-align: right;
					}
					.gdds-item-03-item-icon {
						width: 8px;
						height: 10px;
						margin-bottom: 5px;
						background-size: 100%;
						background-repeat: no-repeat;
						background-image: url(../assets/images/gdds/item-03-icon-01.png);
					}
					.gdds-item-03-item-data {
						flex: 1;
						margin: 0 10px;
						.gdds-item-03-item-data-proportion {
							margin-bottom: 4px;
							text-align: right;
						}
						.gdds-item-03-item-data-border {
							padding: 6px;
							border-radius: 3px;
							border: #5ea7ff30 1px solid;
						}
						.gdds-item-03-item-data-background {
							flex: 1;
							height: 6px;
							border-radius: 10px;
							background: #5ea7ff50;
							.gdds-item-03-item-data-progress {
								position: relative;
								width: 0;
								height: 100%;
								border-radius: 10px;
								background-image: linear-gradient(to right, #1671a3, #a8f9fd);
								transition: all 0.3s;
								.gdds-item-03-item-data-circle {
									position: absolute;
									width: 10px;
									height: 10px;
									top: 50%;
									transform: translateY(-50%);
									right: -5px;
									border-radius: 50%;
									background-color: #d0fdff;
									box-shadow: 0px 0px 12px #fff;
								}
							}
						}
					}
					.gdds-item-03-item-num {
						width: 130px;
						color: #ffffff;
						font-weight: bold;
					}
				}
			}
		}

		.gdds-item-wrap-04 {
			bottom: 0;
			width: 100%;
			height: 486px;
			.gdds-item-wrap-list {
				padding: 18px;
				max-height: 430px;
				overflow-y: scroll;
				&::-webkit-scrollbar {
					display: none;
				}
				.gdds-item-list-item {
					width: 413px;
					height: 40px;
					background-size: 100%;
					display: flex;
					align-items: center;
					background-repeat: no-repeat;
					background-image: url(../assets/images/gdds/item-04-item-01.png);
					margin-bottom: 10px;
					transition: all 0.3s;
					cursor: pointer;
					&:last-child {
						margin-bottom: 0;
					}
					p {
						width: 100%;
						margin-left: 20px;
						font-size: 16px;
					}
				}
				.gdds-item-list-item-active {
					background-image: url(../assets/images/gdds/item-04-item-02.png);
				}
			}
		}
		.gdds-nodata {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			color: #ffffff;
		}
	}
</style>
